import logo from './logo.svg';
import './App.css';
import Navbar from './component/navbar';
import Gallerie from './pages/Gallerie';
import Apropos from './pages/Apropos';
import React, { useState, useEffect } from 'react';
import TheState from './state.json';

function App() {
  const [data, setData] = useState(TheState);

  const availChanger = (id) => {
    setData(prevData => {
      const newData = {...prevData}; // create a copy of the state object
      newData[id].dispo = "no"; // update the copy with the new value
      return newData; // set the state to the new object
    });
  };

  console.log(data[1].dispo)

  let component
  switch (window.location.pathname) {
    case "/":
      component = <Gallerie action={availChanger} data={data} />     
      break;
  case "/gallerie":
    component = <Gallerie action={availChanger}  data={data}/>
    break;
    case "/apropos":
      component = <Apropos/>
      break;

  }
  return (
    <>
    <Navbar/>
    <div className="main">{component}</div>
    
    
    </>
  );
}

export default App;
