import React, { useEffect } from 'react'
import images from '../script/images.js';
import Tableau from '../component/tableau.js';




const Gallerie = (props) => {

  const importAll = (r) => r.keys().map(r);
  const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

  console.log(props.data[1].dispo)

  

  {}
 
  return (

    
    <div className="gallerie">

     
    <h1>Gallerie</h1>
    <div className="boxTableau">
    {Object.keys(props.data).map((image, id) => {

    return (
      <div className='vignette'>
        <Tableau action={props.action} className='preview' img={images[id]} data={props.data[id]} id={id}/>
      </div>
    );
  })}F
     
    </div>
    
    </div>
  )
}

export default Gallerie

