import React, { useState } from 'react'

import clear from '../icons/clear.png'
import shop from '../icons/shopping-bag.png'
import shopGreen from '../icons/shopGreen.png'

// This is the modal component
const Modal = ({ img, isOpen, close, isAvail, action, id, isHelloVisible, setIsHelloVisible }) => {

  const commander = () => {
    setIsHelloVisible(true);
    action(id);
  }

  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 3,
      width: '100%',
      height: '100%',
      padding: '10%',

    }}>
      <div className='preview' style={{
        position: "relative",
        display: "inline-block",
        margin:'90px',

        maxWidth:"100vh",
        maxHeight:"100vh",
  
     }
      }>
        <img className='previewimg' style={{
          width:"100%",
          height:"100%"


        }} src={img} alt="" />
        <a style={{
          
          position: "absolute",
          top: 25,
          right: 25
        }} onClick={close}><img style={{ width: "50px" }} src={clear} ></img></a>
        {/* {(isAvail== "yes")? <img style={{width:"50px",height:"50px",position: "absolute",
          top: 16,
          right: 120}}  onClick={commander} src={shopGreen}></img>:""} */}


        
      </div>

      {/* render the Hello component conditionally */}
      {isHelloVisible && (
        <div style={{
          position: 'fixed',
  
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'black',
          borderRadius:"10px",
          zIndex: 4,
          padding: '20px'

        }}>
          <h3>Faire une offre pour ce tableau</h3>
          <label htmlFor="text">Message and contact</label>
          <br/>
          <textarea rows="4" cols="50" type="text" name="hello" id="text"/>
          <br/>
          <label htmlFor="price">Offre</label>
          <br/>
          <input type="text" name="" id="price" />
          <br/>
          <button onClick={() => setIsHelloVisible(false)}>Close</button>
        </div>
      )}
    </div>
  )
}

const Avail =(isAvail) => {

  if (isAvail.isAvail == "yes") return null;

  return (
  <a><img style={{width:"50px",height:"50px",position: "absolute",
          top: 10,
          right: 10}} src={shop}></img></a>)
}

const Tableau = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isHelloVisible, setIsHelloVisible] = useState(false); // define the state variable and the function

  const openModal = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  return (
    <>
      <img src={props.img} alt="" onClick={openModal} style={{ cursor: 'pointer' }} />
      <h2>{props.data.number}-{props.data.titre}</h2>
      <Avail isAvail={props.data.dispo} ></Avail>
      <Modal action={props.action} id={props.id} isAvail={props.data.dispo} img={props.img} isOpen={modalOpen} close={closeModal} isHelloVisible={isHelloVisible} setIsHelloVisible={setIsHelloVisible} />
      <p>{props.data.largeur}x{props.data.hauteur}</p>
    </>
  )
}

export default Tableau