import alain from '../icons/alain.jpg';

export default function Apropos() {
  return (
    <div className="apropos">
      <h1>A propos</h1>
      <div className="content">
        <div className="image">
          <img src={alain} alt="" />
        </div>
        <div className="textBox">
          <p>Né à Pontorson le 8 novembre 1936, Alain DAVY, ancien élève de l’école de Vanderkalen à Bruxelles, obtient la médaille d’or en 1960. Il participe à de nombreuses expositions, notamment aux salons parisiens comme celui des indépendants. Autodidacte, Alain Davy n’en est pas moins un peintre qui pourrait en apprendre à beaucoup. Sa manière de traiter les sujets, le plus souvent la nature, l’apparente au fauvisme ou aux impressionnistes, sans qu’il y ait pourtant imitation des genres. Alain Davy sait en effet apporter sa touche personnelle, bref créer son propre style. Un style souvent flamboyant au sens littéral du terme. Ses paysages semblent brûler du feu de la lumière qui imprègne ses toiles et jaillit de celles-ci par tous les « pores ».</p>
        </div>
      </div>
    </div>
  );
}