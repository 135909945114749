import { Children } from "react"

export default function Navbar() {


    return (
        <nav className="nav">
            <a href="/gallerie" className="site-title">
                Gallerie d'Alain DAVY
            </a>
            <ul>
            <CustomLink href="/gallerie">gallerie</CustomLink>
                <CustomLink href="/apropos">apropos</CustomLink>

            </ul>
        </nav>

    )
}

function CustomLink({href,children,...props}){
    const path = window.location.pathname

    return(
        <li className={path === href ? "active" : ""}><a href={href} {...props}>{children}</a></li>
    )
}